import {component} from 'picoapp';
import renderPushSections from '../utils/renderPushSections';
import forEach from '../utils/forEach';
import app from './app';
import {remove} from 'lodash';

export default component((node, ctx) => {
	const id = node.dataset.id;
	const quantity = Number(node.dataset.quantity);

	const decrementButton = node.querySelector(
		'button[data-line-update="decrement"]',
	);

	const incrementButton = node.querySelector(
		'button[data-line-update="increment"]',
	);

	const removeButton = node.querySelector(
		'button[data-line-update="remove"]',
	);

	const onUpdateQuantity = newQuantity => async event => {
		event.preventDefault();

		decrementButton.disabled = true;
		incrementButton.disabled = true;
		removeButton.disabled = true;

		decrementButton.classList.add('loading');
		incrementButton.classList.add('loading');
		removeButton.classList.add('loading');

		try {
			const response = await fetch('/cart/update.js', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					updates: {
						[id]: newQuantity,
					},
					sections:
						'push-cart-count,push-cart-subtotal,push-cart-line-items',
				}),
			});

			const {sections} = await response.json();

			if (sections) {
				renderPushSections(sections);

				// initialize new components after inserting into DOM
				// this does not initialize components already initalized
				app.mount();
			}
		} catch (error) {
			console.log(error);
		}

		decrementButton.disabled = false;
		incrementButton.disabled = false;
		removeButton.disabled = false;

		decrementButton.classList.remove('loading');
		incrementButton.classList.remove('loading');
		removeButton.classList.remove('loading');
	};

	const onDecrement = onUpdateQuantity(quantity - 1);
	const onIncrement = onUpdateQuantity(quantity + 1);
	const onRemove = onUpdateQuantity(0);

	decrementButton.addEventListener('click', onDecrement);
	incrementButton.addEventListener('click', onIncrement);
	removeButton.addEventListener('click', onRemove);

	return () => {
		decrementButton.removeEventListener('click', onDecrement);
		incrementButton.removeEventListener('click', onIncrement);
		removeButton.removeEventListener('click', onRemove);
	};
});
