import { component } from 'picoapp';

export default component((node, ctx) => {
	let open = false;

	const toggleButton = node.querySelector('.collectionFilter__toggleButton');
	const closeLabel = node.querySelector('.collectionFilter__closeLabel');
	const body = node.querySelector('.collectionFilter__body');

	const toggle = () => {
		open = !open;
		closeLabel.classList.toggle('hidden', !open);
		body.classList.toggle('hidden', !open);
	};

	toggleButton.addEventListener('click', toggle);

	return () => {
		toggleButton.removeEventListener('click', toggle);
	};
});
