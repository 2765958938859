import {component} from 'picoapp';
import clamp from 'lodash/clamp';

export default component((node, ctx) => {
	let mousePosition = [0.5, 0.5];
	const largeImage = node.querySelector('.zoom-image');

	const onClick = () => {
		if (largeImage.style.display == 'none') {
			largeImage.style.display = null;
			node.classList.remove('cursor-zoom-in');
			node.classList.add('cursor-zoom-out');
		} else {
			largeImage.style.display = 'none';
			node.classList.add('cursor-zoom-in');
			node.classList.remove('cursor-zoom-out');
		}
	};

	const onMouseMove = event => {
		const containerPosition = node.getBoundingClientRect();
		const leftPercentage =
			(event.clientX - containerPosition.left) / containerPosition.width;
		const topPercentage =
			(event.clientY - containerPosition.top) / containerPosition.height;

		mousePosition = [
			clamp(leftPercentage, 0, 1),
			clamp(topPercentage, 0, 1),
		];

		largeImage.style.transform = `translate(
			-${mousePosition[0] * 50}%,
			-${mousePosition[1] * 50}%
		)`;
	};

	node.addEventListener('click', onClick);
	window.addEventListener('mousemove', onMouseMove);

	return () => {
		node.removeEventListener('click', onClick);
		window.removeEventListener('mousemove', onMouseMove);
	};
});
