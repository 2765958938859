import {picoapp} from 'picoapp';

import addressFormDelete from './address-form-delete';
import addressFormNew from './address-form-new';
import addressFormEdit from './address-form-edit';
import login from './login';
import newsletter from './newsletter';
import productTile from './product-tile';
import productHeroForm from './product-hero-form';
import productHeroCarousel from './product-hero-carousel';
import productRecommendations from './product-recommendations';
import collectionHeader from './collection-header';
import collectionFilter from './collection-filter';
import collectionPaginator from './collection-paginator';
import image from './image';
import drawer from './drawer';
import pageDrawer from './page-drawer';
import zoomImage from './zoom-image';
import header from './header';
import menuTrigger from './menu-trigger';
import menuTriggerExit from './menu-trigger-exit';
import headerMenu from './header-menu';
import cartLineItem from './cart-line-item';

const state = {
	activeMenuId: null,
};

const components = {
	addressFormDelete,
	addressFormEdit,
	addressFormNew,
	newsletter,
	login,
	productTile,
	productHeroForm,
	productHeroCarousel,
	productRecommendations,
	collectionHeader,
	collectionFilter,
	collectionPaginator,
	image,
	drawer,
	pageDrawer,
	zoomImage,
	header,
	menuTrigger,
	menuTriggerExit,
	headerMenu,
	cartLineItem,
};

export default picoapp(components, state);
