import {component} from 'picoapp';

export default component((node, ctx) => {
	const trigger = node.querySelector('button');
	const body = node.querySelector('.drawer-body');
	const indicator = node.querySelector('.drawer-indicator');

	const onClick = () => {
		if (body.style.display === 'none') {
			body.style.display = null;
			indicator.classList.add('active');
		} else {
			body.style.display = 'none';
			indicator.classList.remove('active');
		}
	};

	trigger.addEventListener('click', onClick);

	return () => {
		trigger.removeEventListener('click', onClick);
	};
});
