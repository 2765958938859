import forEach from './forEach';

export default pushSections => {
	const sectionIds = Object.keys(pushSections);

	forEach(sectionIds, id => {
		const value = pushSections[id];
		const el = document.getElementById(id);

		if (el) {
			el.innerHTML = value;
		}
	});
};
