import {component} from 'picoapp';
import isTouchEnabled from '../utils/isTouchEnabled';

export default component((node, ctx) => {
	const isTouch = isTouchEnabled();
	const hoverImage = node.querySelector('.product-tile__hover');

	const onMouseEnter = () => {
		hoverImage.style.display = '';
	};

	const onMouseLeave = () => {
		hoverImage.style.display = 'none';
	};

	if (!isTouch) {
		node.addEventListener('mouseenter', onMouseEnter);
		node.addEventListener('mouseleave', onMouseLeave);
	}

	return () => {
		if (!isTouch) {
			node.removeEventListener('mouseenter', onMouseEnter);
			node.removeEventListener('mouseleave', onMouseLeave);
		}
	};
});
