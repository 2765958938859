import {component} from 'picoapp';

export default component((node, ctx) => {
	const input = node.querySelector('input');

	ctx.on('menu', state => {
		if (state.activeMenuId === node.dataset.menu) {
			node.style.display = null;

			if (input) {
				input.focus();
			}
		} else {
			node.style.display = 'none';
		}
	});
});
