import {component} from 'picoapp';
import KeenSlider from 'keen-slider';

export default component((node, ctx) => {
	const sliderEl = node.querySelector('.keen-slider');
	const indexEl = node.querySelector('.product-hero-carousel__count');

	const slider = new KeenSlider(sliderEl, {
		loop: true,
		slides: {
			perView: 1,
			spacing: 10,
		},
		created(slider) {
			console.log(slider);
		},
		slideChanged(slider) {
			indexEl.innerHTML = slider.track.details.rel + 1;
		},
	});

	return () => {
		slider.destroy();
	};
});
