import {component} from 'picoapp';
import viewport from '../utils/viewport';
import getViewportPosition from '../utils/getViewportPosition';

const SCROLL_THRESHOLD = 5;

export default component((node, ctx) => {
	const onScroll = () => {
		const top = getViewportPosition();
		const {activeMenuId} = ctx.getState();

		if (top >= SCROLL_THRESHOLD || !!activeMenuId) {
			node.classList.add('header-scrolled');
		} else {
			node.classList.remove('header-scrolled');
		}
	};

	ctx.on('menu', onScroll);
	viewport.listen(onScroll);

	return () => {
		viewport.unlisten(onScroll);
	};
});
