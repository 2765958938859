import {component} from 'picoapp';

export default component((node, ctx) => {
	const activeMenuId = node.dataset.menu;

	const onMouseEnter = () => {
		ctx.emit('menu', {activeMenuId});
	};

	const onClick = () => {
		if (activeMenuId === ctx.getState().activeMenuId) {
			ctx.emit('menu', {activeMenuId: null});
		} else {
			ctx.emit('menu', {activeMenuId});
		}
	};

	ctx.on('menu', state => {
		if (state.activeMenuId === activeMenuId) {
			node.classList.add('active');
		} else {
			node.classList.remove('active');
		}
	});

	node.addEventListener('mouseenter', onMouseEnter);
	node.addEventListener('click', onClick);

	return () => {
		node.removeEventListener('mouseenter', onMouseEnter);
		node.removeEventListener('click', onClick);
	};
});
