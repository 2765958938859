import {component} from 'picoapp';
import getViewportPosition from '../utils/getViewportPosition';
import min from 'lodash/min';
import max from 'lodash/max';

export default component((node, ctx) => {
	const onScroll = () => {
		const size = node.getBoundingClientRect();
		const maxScroll = size.height / 2;
		const top = getViewportPosition();
		const percentage = top / maxScroll;
		const maxPercentage = max([min([percentage, 1]), 0]);

		node.style.opacity = 1 - maxPercentage;
	};

	window.addEventListener('scroll', onScroll);
	window.addEventListener('resize', onScroll);

	return () => {
		window.removeEventListener('scroll', onScroll);
		window.removeEventListener('resize', onScroll);
	};
});
