import {component} from 'picoapp';
import renderPushSections from '../utils/renderPushSections';
import forEach from '../utils/forEach';
import app from './app';

export default component((node, ctx) => {
	const variantSelect = node.querySelector('.product-form-variant-select');
	const variantOptions = node.querySelectorAll(
		'.product-form-variant-button',
	);
	const submitButton = node.querySelector('button[type="submit"]');

	const variantOptionListeners = [];

	// Sync states of variant buttons
	forEach(variantOptions, (option, optionIndex) => {
		variantOptionListeners[optionIndex] = () => {
			variantSelect.value = option.value;
		};

		option.addEventListener('change', variantOptionListeners[optionIndex]);
	});

	const onChangeVariantSelect = () => {
		forEach(variantOptions, option => {
			if (option.value === variantSelect.value) {
				option.checked = true;
			} else {
				option.checked = null;
			}
		});
	};

	variantSelect.addEventListener('change', onChangeVariantSelect);

	const onSubmit = async event => {
		event.preventDefault();

		submitButton.disabled = true;
		submitButton.classList.add('loading');

		try {
			const activeVariantId = Number(variantSelect.value);

			const response = await fetch('/cart/add.js', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					items: [
						{
							id: activeVariantId,
							quantity: 1,
						},
					],
					sections: 'push-cart-count',
				}),
			});

			const {sections} = await response.json();

			if (sections) {
				renderPushSections(sections);

				// initialize new components after inserting into DOM
				// this does not initialize components already initalized
				app.mount();
			}
		} catch (error) {
			console.log(error);
		}

		submitButton.disabled = false;
		submitButton.classList.remove('loading');

		window.location = '/cart';
	};

	// Submit listener
	node.addEventListener('submit', onSubmit);

	return () => {
		variantSelect.removeEventListener('change', onChangeVariantSelect);
		node.removeEventListener('submit', onSubmit);

		forEach(variantOptions, (option, optionIndex) => {
			option.removeEventListener(
				'change',
				variantOptionListeners[optionIndex],
			);
		});
	};
});
