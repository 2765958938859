import {component} from 'picoapp';

export default component((node, ctx) => {
	const closeButton = node.querySelector('.page-drawer-close');

	const onHashChange = () => {
		if (location.hash === `#${node.dataset.hash}`) {
			node.style.display = null;
		} else {
			node.style.display = 'none';
		}
	};

	window.addEventListener('hashchange', onHashChange);
	onHashChange();

	return () => {
		window.removeEventListener('hashchange', onHashChange);
	};
});
