import {component} from 'picoapp';

const MENU_TIMEOUT = 300;

export default component((node, ctx) => {
	let timeout;

	const onMouseLeave = () => {
		timeout = setTimeout(() => {
			ctx.emit('menu', {activeMenuId: null});
		}, MENU_TIMEOUT);
	};

	const onMouseEnter = () => {
		clearTimeout(timeout);
	};

	node.addEventListener('mouseleave', onMouseLeave);
	node.addEventListener('mouseenter', onMouseEnter);

	return () => {
		node.removeEventListener('mouseleave', onMouseLeave);
		node.removeEventListener('mouseenter', onMouseEnter);
	};
});
