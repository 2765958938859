import app from '../components/app';
import captcha from '../components/captcha';

// Start up app
app.mount();

// Side effects
captcha();

// Code credit
console.groupCollapsed('Site Credits');
console.log('Web Design by Goods https://goods.no');
console.log('Web Development by Gardener NYC https://www.gardener.nyc');
console.groupEnd();
