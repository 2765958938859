import {component} from 'picoapp';
import app from './app';

export default component((node, ctx) => {
	const fetchProducts = async () => {
		try {
			const response = await fetch(
				`/recommendations/products?section_id=product-recommendations&product_id=${node.dataset.productId}&limit=4&intent=related`,
			);
			const responseText = await response.text();
			const recommendations = document.createElement('div');
			recommendations.innerHTML = responseText;

			node.appendChild(recommendations);

			// initialize new components after inserting into DOM
			// this does not initialize components already initalized
			app.mount();
		} catch (error) {
			console.log(error);
		}
	};

	fetchProducts();
});
