import {component} from 'picoapp';
import app from './app';

export default component((node, ctx) => {
	const target = document.body.querySelector(node.dataset.target);
	const labelDefault = node.querySelector(
		'.collectionPaginator__labelDefault',
	);
	const labelLoading = node.querySelector(
		'.collectionPaginator__labelLoading',
	);
	const labelError = node.querySelector('.collectionPaginator__labelError');

	let curPage = target.dataset.page;
	let nextPageURL = node.getAttribute('href');
	let loading = false;

	const parser = new DOMParser();

	const onClick = event => {
		event.preventDefault();
		loadNextPage();
	};

	const loadNextPage = async () => {
		if (loading) return;
		loading = true;

		labelLoading.classList.remove('hidden');
		labelDefault.classList.add('hidden');
		labelError.classList.add('hidden');
		node.classList.add('opacity-50');

		try {
			const res = await fetch(nextPageURL);
			if (!res.ok) throw new Error(res.statusText);
			const text = await res.text();
			const body = parser.parseFromString(text, 'text/html');
			const content = body.querySelector(node.dataset.target);

			if (!content)
				throw new Error(
					`Target not found in returned document: ${node.dataset.target}`,
				);

			while (content.children.length) {
				target.appendChild(content.children[0]);
			}

			// initialize new components after inserting into DOM
			// this does not initialize components already initalized
			app.mount();

			curPage = content.dataset.page;
			nextPageURL = content.dataset.nextPageUrl;

			labelDefault.classList.remove('hidden');
			labelLoading.classList.add('hidden');
			labelError.classList.add('hidden');
			node.classList.remove('opacity-50');

			if (!nextPageURL || nextPageURL == '') {
				node.parentElement.classList.add('hidden');
			}
		} catch (e) {
			console.error(e);
			labelDefault.classList.add('hidden');
			labelLoading.classList.add('hidden');
			labelError.classList.remove('hidden');
			node.classList.remove('opacity-50');
		}
		loading = false;
	};

	node.addEventListener('click', onClick);

	return () => {
		node.removeEventListener('click', onClick);
	};
});
